.contact {
	background-image: linear-gradient(to top, #f2e7d2, #f2e7d2, #fffafa);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 100%;
	height: 100%;
	width: 100%;
	padding-top: 20px;
}

input {
	width: 100%;
	height: 30px;
}

.contactUs {
	width: 100%;
	padding-bottom: 20px;

	font-size: 21px;
	font-weight: bold;
	text-decoration: underline;
	text-align: center;
}

.form {
	display: flex;
	flex-direction: column;
	width: 600px;
	padding: 40px;
}

.field {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.field:not(:last-child) {
	margin-right: 20px;
}

.fieldWrapper {
	display: flex;
}

.message {
	width: 100%;
	height: 100%;

	resize: none;
}

.submit {
	background-color: rgb(53, 53, 53);
	color: #FFF;

	border: none;
	outline: none;

	width: 100%;
	height: 40px;
	margin-top: 20px;

	cursor: pointer;
}

.submit:disabled {
	background-color: rgb(199, 199, 199);

	cursor: no-drop;
}

.success {
	color: green;
}

.error {
	color: red;
}

@media only screen and (max-width: 650px) {

	.contactUs {
		padding-bottom: 10px;
		padding-top: 40px;
	}

	.form {
		width: 350px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 0;
		padding-right: 0;
	}
}