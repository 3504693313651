.carousel-root {
    display: flex;
    justify-content: center;
    width: 100%;
}


@media only screen and (max-width: 1000px) {

    .carousel-image {
        max-width: 240px;
        max-height: 180px;
    }
}

@media only screen and (max-width: 650px) {

    .carousel-image {
        max-width: 120px;
        max-height: 80px;
    }
}