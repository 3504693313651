.home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	flex: 1 1 100%;
	height: 100%;
	width: 100%;
}

.main-image {
	max-width: 100%;
	height: auto;

	/* ie8 */
	width: auto\9;
}

.hover-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
}

@keyframes animation-move-logo {
	from {
		top: -100px;
	}

	to {
		top: 200px;
	}
}

.logo-hover-main-img {
	position: absolute;
	top: -100px;
	width: 100%;
	left: 0;

	text-align: center;

	animation: animation-move-logo 1.2s;
	animation-fill-mode: forwards;
}

.title-hover-main-img {
	position: absolute;
	top: 300px;
	width: 100%;
	left: 0;

	text-align: center;
	text-shadow: 2px 2px 2px black;
	font-size: x-large;
	font-weight: 400;
	line-height: 30px;
	color: #fff;
}

.home-logo {
	cursor: pointer;
}

@media only screen and (max-width: 1000px) {

	.title-hover-main-img {
		top: 250px;
	}

	@keyframes animation-move-logo {
		from {
			top: -100px;
		}

		to {
			top: 150px;
		}
	}
}

@media only screen and (max-width: 650px) {

	.title-hover-main-img {
		top: 100px;

		line-height: 25px;
	}

	.home-logo {
		max-width: 60px;
		height: auto;
	}

	@keyframes animation-move-logo {
		from {
			top: -100px;
		}

		to {
			top: 50px;
		}
	}
}