.menu {
	position: sticky;
	background-color: rgb(53, 53, 53);
	top: 0;
	width: 100%;

	z-index: 5;
}

.menuWeb {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 100%;
}

.menuButton {
	border-right-style: solid;
	border-right-width: 1px;
	border-color: rgb(175, 175, 175, 0.4);

	padding-left: 30px;
	padding-right: 30px;

	color: #FFF;
	font-size: 14px;

	cursor: pointer;
}

.menuButtonWrapper {
	display: flex;
	align-items: center;
}

.menuButtonWrapper:first-child {

	border-left-style: solid;
	border-left-width: 1px;
	border-color: rgb(175, 175, 175, 0.4);
}

.menuFlag {
	width: 24px;
	padding-left: 10px;
	padding-right: 10px;

	cursor: pointer;
}

.flagsWrapper {
	background-color: rgb(53, 53, 53);

	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 100px;
	height: 40px;
}

.menuMobile {
	display: none;
}


@media only screen and (max-width: 650px) {

	.menuWeb {
		display: none;
	}

	.menuMobile {
		display: flex;
		justify-content: flex-end;
		height: 40px;
		margin-right: 10px;
	}

	.menuBurger {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.menuMobileOpen {
		background-color: rgb(53, 53, 53);

		position: absolute;
		width: 40%;
		right: 0;
	}

	.flagsWrapper,
	.menuMobileOption {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.flagMobile {
		display: flex;
		align-items: flex-start;
		height: 40px;
		padding-left: 20px;

		font-size: 14px;
		color: #FFF;
	}

	.separator {
		background-color: rgb(175, 175, 175);
		opacity: 0.4;

		align-self: center;
		height: 1px;
		width: 90%;
		margin-bottom: 20px;
	}
}