.about {
	background-image: linear-gradient(to bottom, #f2e7d2, #f2e7d2, #fffafa);

	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 100%;
	height: 100%;
	width: 100%;
	padding-bottom: 60px;
	padding-top: 20px;

	text-align: center;
}

.about-text {
	padding-bottom: 10px;
}

.btn-redirection-wrapper {
	display: flex;
	width: 50%;
	justify-content: space-around;
	padding-top: 30px;
}

.btn-redirection {
	background-color: rgb(53, 53, 53);
	color: #FFF;

	border: none;
	outline: none;

	width: 180px;
	height: 60px;

	cursor: pointer;
}

@media only screen and (max-width: 650px) {

	.about {
		padding-bottom: 40px;
		padding-top: 10px;
	}

	.about-text {
		padding: 10px;
	}

	.btn-redirection-wrapper {
		width: 100%;
	}

	.btn-redirection {
		width: 120px;
	}
}