.products {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.product-title {
	display: flex;
	justify-content: center;
	padding-bottom: 10px;
	margin: 0;

	font-weight: bold;
	font-size: x-large;
	text-decoration: underline;
}

.right {
	background-image: linear-gradient(to right, #f0f0d8, #fffafa);
}

.left {
	background-image: linear-gradient(to left, #f0f0d8, #fffafa);
}

.description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 300px;
}

.text {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	padding-top: 40px;

	z-index: 2;
}

.wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	width: 100%;
	height: 340px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.paragraph {
	padding: 10px;
	padding-left: 30px;
	padding-right: 30px;
}

.image {
	background-color: #f0f0d8;

	display: flex;
	align-items: center;
	height: 300px;
}

@media only screen and (max-width: 1120px) {

	.wrapper {
		height: 500px;
	}

	.image,
	.description {
		height: 460px;
	}
}

@media only screen and (max-width: 750px) {

	.image {
		opacity: 0.4;
	}

	.wrapper {
		position: relative;
		justify-content: center;
		height: 550px;
	}

	.text {
		position: absolute;
	}

	.description {
		background: none;
		height: 500px;
	}

	.product-title {
		padding-bottom: 0px;
	}
}

@media only screen and (max-width: 430px) {

	.image {
		opacity: 0.4;
	}

	.wrapper {
		position: relative;
		justify-content: center;
		height: 630px;
	}

	.text {
		position: absolute;
	}

	.description {
		background: none;
		height: 600px;
	}

}