.root {
	background-color: #fffafa;

	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	height: 100%;
	width: 100%;

	font-size: 15px;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.carousel {
	padding-bottom: 25px;
	padding-top: 25px;
}

@media only screen and (max-width: 1000px) {
	.root {
		font-size: 14px;
	}
}

@media only screen and (max-width: 650px) {

	.carousel {
		padding-bottom: 0;
		padding-top: 0;
	}
}